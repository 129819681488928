import React from 'react';
import { ProjectBadges } from './project-badges';
import { GroupBadges } from './group-badges';
import { NetworkBadges } from './network-badges';
import { PostTypeBadges } from './post-type-badges';
import { IncludeEstimatesBadge } from './include-estimates-badge';
import { IncludeUnassignedBadge } from './include-unassigned-badge';
import { Project, Group } from '../filters-form/types';
import { NetworkValue, PostTypeValue } from '../filters-form';

interface SocialAnalyticsFilterBadgesProps {
  projectBadgesProps: {
    data: Project[];
  };
  groupBadgesProps: {
    data: Group[];
  };
  networkBadgesProps: {
    data: { value: NetworkValue; label: string; }[];
  };
  postTypeBadgesProps: {
    data: { value: PostTypeValue; label: string; }[];
  };
  includeEstimatesBadgeProps: {
    data: { value: string; label: string; }[];
  };
  includeUnassignedBadgeProps: {
    data: { value: string; label: string; }[];
  };
  onOpenFilters: () => void;
  onRemoveFilters: (type: string) => (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SocialAnalyticsFilterBadges: React.FC<SocialAnalyticsFilterBadgesProps> = ({
  projectBadgesProps,
  groupBadgesProps,
  networkBadgesProps,
  postTypeBadgesProps,
  includeEstimatesBadgeProps,
  includeUnassignedBadgeProps,
  onOpenFilters,
  onRemoveFilters,
}) => {
  return (
    <>
      <ProjectBadges
        {...projectBadgesProps}
        onClick={onOpenFilters}
        onDismiss={onRemoveFilters('projectIds')}
      />
      <GroupBadges
        {...groupBadgesProps}
        onClick={onOpenFilters}
        onDismiss={onRemoveFilters('groupIds')}
      />
      <NetworkBadges
        {...networkBadgesProps}
        onClick={onOpenFilters}
        onDismiss={onRemoveFilters('networks')}
      />
      <PostTypeBadges
        {...postTypeBadgesProps}
        onClick={onOpenFilters}
        onDismiss={onRemoveFilters('postTypes')}
      />
      <IncludeEstimatesBadge
        {...includeEstimatesBadgeProps}
        onClick={onOpenFilters}
        onDismiss={onRemoveFilters('includeEstimates')}
      />
      <IncludeUnassignedBadge
        {...includeUnassignedBadgeProps}
        onClick={onOpenFilters}
        onDismiss={onRemoveFilters('includeUnassigned')}
      />
    </>
  );
};
