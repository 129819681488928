interface IncludeUnassignedBadge {
  includeUnassigned: boolean;
}

export const useIncludeUnassignedBadge = ({ includeUnassigned }: IncludeUnassignedBadge) => {
  return {
    includeUnassignedBadgeProps: {
      data: [{
        value: includeUnassigned?.toString() || 'false',
        label: includeUnassigned ? 'True' : 'False'
      }],
    }
  };
};
